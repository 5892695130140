import {useEffect} from 'react';
import {storeGeneral} from './Store/General';
import {useScripts} from './Services/Scripts';
import {Header} from './Components/Header';
import {
  TabContent,
  TabItem,
  Tabs,
  TabsContent,
  TabsNavigation,
} from './Components/Tabs';
import styles from './App.module.scss';
import {CongratulationsPage} from './Components/CongratulationsPage';
import {MembershipsPage} from './Components/MembershipsPage';
import {DetailsForm} from './Components/DetailsForm';
import {TermsPopup} from './Components/TermsPopup';
import {SummaryPage} from './Components/SummaryPage';
import {PaymentPopup} from './Components/PaymentPopup';
import {ClubsPage} from './Components/ClubsPage';
import { BrowserRouter } from 'react-router-dom';

import CacheBuster from 'react-cache-buster';
const version = '0.2.1';

function App() {
  useEffect(() => {
    storeGeneral.setCityFromURL();
  }, []);
  useScripts('GTM-PF2635M');

  const isProduction = process.env.NODE_ENV === 'production';

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
    >
      <div className={styles.container}>
        <Header />
        {storeGeneral.city ? (
          <Tabs>
            <TabsNavigation>
              <TabItem index={0}>Plans</TabItem>
              <TabItem index={1}>Your Details</TabItem>
              <TabItem index={2}>Terms & Conditions</TabItem>
              <TabItem index={3}>Complete Registration</TabItem>
            </TabsNavigation>
            <TabsContent>
              <TabContent>
                <MembershipsPage nextTab={storeGeneral.navigation.next} />
              </TabContent>

              <TabContent>
                <DetailsForm
                  prevTab={storeGeneral.navigation.prev}
                  nextTab={storeGeneral.navigation.next}
                />
              </TabContent>

              <TabContent>
                <TermsPopup
                  city={storeGeneral.city}
                  onNext={storeGeneral.navigation.next}
                  onPrev={storeGeneral.navigation.prev}
                />
              </TabContent>

              <TabContent>
                <SummaryPage
                  prevTab={storeGeneral.navigation.prev}
                  nextTab={storeGeneral.navigation.next}
                  editDetails={storeGeneral.goToDetails}
                />
              </TabContent>

              <TabContent>
                <PaymentPopup
                  city={storeGeneral.city}
                  nextTab={storeGeneral.navigation.next}
                  prevTab={storeGeneral.navigation.prev}
                />
              </TabContent>

              <BrowserRouter>
                <TabContent>
                  <CongratulationsPage />
                </TabContent>
              </BrowserRouter>
            </TabsContent>
          </Tabs>
        ) : (
          <Tabs>
            <ClubsPage nextTab={storeGeneral.navigation.next} />
          </Tabs>
        )}
      </div>
    </CacheBuster>
  );
}

export default App;
