import React, {useCallback} from 'react';
import {Input} from '../Input';
import {Form} from 'react-final-form';
import styles from './DetailsForm.module.scss';
import {Select} from '../Select';
import * as yup from 'yup';
import {validate} from '../../Helpers/validate';
import {Button} from '../Button';
import {storeGeneral} from '../../Store/General';
import {FieldDatePicker} from '../DatePicker';
import {InputPhone} from '../InputPhone';
import {InputPostCode} from '../InputPostCode';
import {parse, isDate} from 'date-fns';
import {SALESPERSON_OPTIONS} from '../../Constants';
import {InputCC} from '../InputCC';
import {InputExpiry} from '../InputExpiry';
import {InputCVV} from '../InputCVV';
import {checkInstagram} from '../../Helpers/checkInstagram';

const GENDER_OPTIONS = [
  {value: 'Male', label: 'Male'},
  {value: 'Female', label: 'Female'},
  {value: 'Other', label: 'Other'},
  {value: 'Unspecified', label: 'Unspecified'},
];

const PROVINCE_OPTIONS = [
  {value: 'AB', label: 'Alberta'},
  {value: 'BC', label: 'British Columbia'},
  {value: 'MB', label: 'Manitoba'},
  {value: 'NB', label: 'New Brunswick'},
  {value: 'NL', label: 'Newfoundland and Labrador'},
  {value: 'NT', label: 'Northwest Territories'},
  {value: 'NS', label: 'Nova Scotia'},
  {value: 'NU', label: 'Nunavut'},
  {value: 'ON', label: 'Ontario'},
  {value: 'PE', label: 'Prince Edward Island'},
  {value: 'QC', label: 'Quebec'},
  {value: 'SK', label: 'Saskatchewan'},
  {value: 'YT', label: 'Yukon'},
];
const today = new Date();

function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'yyyy-MM-dd', new Date());

  return parsedDate;
}

const validationSchema = yup.object().shape({
  firstName: yup.string().required('First name is required'),
  lastName: yup.string().required('Last name is required'),
  birthday: yup
    .date()
    .transform(parseDateString)
    .max(today, 'Hey, that is too young!')
    .required('You forgot to pick a birthdate'),
  gender: yup.string().required('You forgot to pick your Gender'),
  phoneNumber: yup.string().required('Phone number is required'),
  email: yup
    .string()
    .required('Email is required')
    .email('Email format: example@mail.com'),
  street: yup.string().required('Street is required'),
  addressCity: yup.string().required('City is required'),
  province: yup.string().required('Province is required'),
  postalCode: yup
    .string()
    .required('Postal code is required')
    .min(7, 'This should be 6 characters, e.g. K1A 0B1')
    .max(7, 'This should be 6 characters, e.g. K1A 0B1'),
  emergencyContactName: yup.string().required('Full name is required'),
  emergencyContactPhone: yup.string().required('Phone number is required'),
  ccNumber: checkInstagram()
    ? yup
        .string()
        .required('16-digit CC number is required')
        .min(19, 'Please enter 16 digits')
        .max(19, 'Please enter 16 digits')
    : null,
  ccExpiry: checkInstagram()
    ? yup.string().required('CC expiry date is required')
    : null,
  ccCVV: checkInstagram()
    ? yup
        .string()
        .required('3-digit CVV number is required')
        .min(3, '3 digits')
        .max(3, '3 digits')
    : null,
});

export function DetailsForm({prevTab, nextTab}) {
  let accept = useCallback((result) => {
    storeGeneral.user.setData(result);
    nextTab();
  }, []);

  return (
    <>
      <div className={styles.header}>
        <div className={styles.title}>YOUR DETAILS</div>
        <div className={styles.description}>All fields are required</div>
      </div>
      <Form
        onSubmit={accept}
        initialValues={storeGeneral.user}
        validate={(values) => {
          return validate(validationSchema, values);
        }}>
        {({handleSubmit}) => (
          <>
            <div className={styles.content}>
              <div className={styles.group}>
                <div className={styles.groupTitle}>Personal details</div>
                <div className={styles.groupContent}>
                  <Input
                    className={styles.groupItem}
                    name="firstName"
                    placeholder="First Name"
                  />
                  <Input
                    className={styles.groupItem}
                    name="lastName"
                    placeholder="Last Name"
                  />
                  <FieldDatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    isSearchable={true}
                    className={styles.groupItem}
                    name="birthday"
                    placeholder="Birthday"
                  />
                  <Select
                    name="gender"
                    className={styles.groupItem}
                    options={GENDER_OPTIONS}
                    placeholder="Gender"
                    defaultValue={storeGeneral.user.gender}
                  />
                </div>
              </div>
              <div className={styles.group}>
                <div className={styles.groupTitle}>Contact details</div>
                <div className={styles.groupContent}>
                  <InputPhone
                    className={styles.groupItem}
                    name="phoneNumber"
                    placeholder="Phone Number"
                  />
                  <Input
                    className={styles.groupItem}
                    name="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className={styles.group}>
                <div className={styles.groupTitle}>Address</div>
                <div className={styles.groupContent}>
                  <Input
                    className={styles.groupItem}
                    name="street"
                    placeholder="Street"
                  />
                  <Input
                    className={styles.groupItem}
                    name="addressCity"
                    placeholder="City"
                  />
                  <Select
                    className={styles.groupItem}
                    name="province"
                    options={PROVINCE_OPTIONS}
                    placeholder="Province"
                    defaultValue={storeGeneral.user.province}
                  />
                  <InputPostCode
                    className={styles.groupItem}
                    name="postalCode"
                    placeholder="Postal code, e.g. K1A 0B1"
                  />
                  {/*<Input
                    className={styles.groupItem}
                    name="country"
                    readOnly={true}
                    defaultValue="Canada"
                  />*/}
                </div>
              </div>
              <div className={styles.group}>
                <div className={styles.groupTitle}>Emergency Contact</div>
                <div className={styles.groupContent}>
                  <Input
                    className={styles.groupItem}
                    name="emergencyContactName"
                    placeholder="Full Name"
                  />
                  <InputPhone
                    className={styles.groupItem}
                    name="emergencyContactPhone"
                    placeholder="Phone Number"
                  />
                </div>
              </div>
              <div className={styles.group}>
                { storeGeneral.city === 'wpg'
                  ?
                  <div>
                  <div className={styles.groupTitle}>
                    Promo code and Salesperson
                    <span className={styles.groupAdditionText}>(Optional)</span>
                  </div>
                  <div className={styles.groupContent}>
                    <Input
                      className={styles.groupItem}
                      name="promoCode"
                      placeholder="Enter a promo code"
                    />
                    <Select
                      className={styles.groupItem}
                      name="salesperson"
                      options={SALESPERSON_OPTIONS[storeGeneral.city]}
                      placeholder="Salesperson (Optional)"
                    />
                  </div>
                  </div>
                  : 
                  <div>
                  <div className={styles.groupTitle}>
                    Salesperson and Referred By
                    <span className={styles.groupAdditionText}></span>
                  </div>
                  <div className={styles.groupContent}>
                    <Select
                      className={styles.groupItem}
                      name="salesperson"
                      options={SALESPERSON_OPTIONS[storeGeneral.city]}
                      placeholder="Salesperson (Optional)"
                    />
                    <Input
                      className={styles.groupItem}
                      name="promoCode"
                      placeholder="Referred By"
                    />
                  </div>
                  </div>
                }
              </div>
              {checkInstagram() ? (
                <div className={styles.group}>
                  <div className={styles.groupTitle}>
                    Payment Method Details
                  </div>
                  <div className={styles.groupContent}>
                    <InputCC
                      className={styles.groupItem}
                      name="ccNumber"
                      placeholder="Enter the 16-digit number"
                    />
                    <InputExpiry
                      className={styles.groupItem}
                      name="ccExpiry"
                      placeholder="Expiry date in the MM/YY format, e.g. 11/24"
                    />
                    <InputCVV
                      className={styles.groupItem}
                      name="ccCVV"
                      placeholder="Enter the 3-digit CVV without spaces"
                    />
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.buttons}>
              <Button
                text="back"
                onClick={prevTab}
                className={styles.button}
                theme="inverse"
              />
              <Button
                text="next"
                onClick={handleSubmit}
                className={styles.button}
              />
            </div>
          </>
        )}
      </Form>
    </>
  );
}
